














































import { Component, Mixins } from 'vue-property-decorator'
import { VueConstructor } from 'vue'

import { OrdersListItemMixin } from '../../../shared/mixins/ordersListItem.mixin'

import OrderStatus from '../OrderStatus/OrderStatus.vue'

import TipIcon from './partials/TipIcon.vue'
import {
  ORDER_LIST_ITEM_COMPONENT_CONFIG_MAP, ORDER_LIST_ITEM_COMPONENT_KEY,
  statusIconsRegistry
} from './OrdersListItem.config'
import { StructureConfigurable } from '../../../../support/mixins'
import { AnyObject } from '@movecloser/front-core'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
@Component<OrdersListItem>({
  name: 'OrdersListItem',
  components: {
    TipIcon,
    OrderStatus
  },
  created () {
    this.config = this.getComponentConfig(ORDER_LIST_ITEM_COMPONENT_KEY, { ...ORDER_LIST_ITEM_COMPONENT_CONFIG_MAP })
  }
})
export class OrdersListItem extends Mixins(OrdersListItemMixin, StructureConfigurable) {
  /**
   * Toggle status component with icon
   * @see statusIconsRegistry
   */
  public get statusIcon (): VueConstructor {
    return statusIconsRegistry[this.item.status]
  }

  public get detailsButtonVariant (): AnyObject {
    return this.getConfigProperty<AnyObject>('variant')
  }

  public get detailsButtonTheme (): AnyObject {
    return this.getConfigProperty<AnyObject>('theme')
  }
}

export default OrdersListItem
