


















































import { Component, Inject, Mixins } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'

import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../support'
import {
  ProductOrderData
} from '../../../../contexts/orders/contracts/orders'

import { mapMonthName } from '../../../shared/support/months-mapper'
import { OrdersListItemMixin } from '../../../shared/mixins/ordersListItem.mixin'
import { OrderStatusesMixin } from '../../../shared/mixins/orderStatuses.mixin'
import { ProductCartMixin } from '../../../checkout/shared/mixins/product-cart.mixin'
import { RestoreOrderMixin } from '../../../shared/mixins/restoreOrder.mixin'

import TipIcon from './partials/TipIcon.vue'

/**
 * Component rendering version of OrdersListItem that has a "Retry payment" functionality
 *
 * @author Filip Rurak <filip.rurak@movecloser.pl> (original)
 */
@Component<OrdersListItemPayment>({
  name: 'OrdersListItemPayment',
  components: { TipIcon }
})
export class OrdersListItemPayment extends Mixins(
  OrdersListItemMixin,
  ProductCartMixin,
  RestoreOrderMixin,
  OrderStatusesMixin
) {
  @Inject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  /**
   * Get status that should enable retry payment action
   */
  public get statusesToPaymentRetry (): Array<string> {
    return Object.keys(this.retryPaymentOrderStatuses)
  }

  /**
   * Date formatted with Locale months names
   * @param date
   */
  public fullFormatDate (date: string) {
    const dateStr = new Date(date.replaceAll('-', '/').replaceAll('.', '/'))
    return `${dateStr.getDate()} ${mapMonthName(
      dateStr.getMonth(),
      this.$i18n
    )} ${dateStr.getFullYear()}`
  }

  /**
   * Used to mark status DOM Element with proper class
   * @param status
   */
  public highlightStatus (status: string) {
    return Object.keys(this.highlightedOrderStatuses).includes(status)
  }

  /**
   * Handle redirect to cart
   */
  public async handleCartRestore (): Promise<RawLocation | undefined> {
    if (!this.cartService) {
      return
    }

    /**
     * @inheritDoc
     */
    await this.createNewCartFromOrder(this.item.products as ProductOrderData[])

    if (this.isSuccessful) {
      this.$emit('deleteRestoredOrder')
    }
  }
}

export default OrdersListItemPayment
